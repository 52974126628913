import styled from 'styled-components'

const MainContentHolder = styled.div`
    h3 {
        font: normal 25px/35px Open Sans;
		color: #584a3e;
		margin: 30px 0 20px;
		font-weight: 300!important;
    }

    .apartment-description {
        margin: 30px 0;

        span { 
            white-space: pre-line;
        }
    }
    .apartmentItemList li {
        margin-top: 10px;
        padding-left: 10px;
        line-height: 25px;

        &:before {
            content: '✓';
            color: #a8d700;
            font-weight: bold;
        }

        span {
            padding-left: 5px;
        }
    }

    .table-price-holder {
        width: 100%;
    }
    
    .table-prices-single {
        margin: 1em auto;
        width: 100%;
        max-width: 600px;
        text-align: center;
        background: #fff;
        border: 1px solid #167f92;

        tr {
            border: 1px solid #d9e4e6;
            &:nth-child(even) {
                background-color: #eaf3f3;
            }
        }

        th {
            display: none;
            border: 1px solid #d9e4e6;
            background-color: #167f92;
            color: #fff;
        }

        td {
            word-wrap: break-word;
            border: 1px solid #d9e4e6;
        }

        th,
        td {
            text-align: center;
            margin: 0.5em 1em;
            display: table-cell;
            padding: 1em;
        }

        @media screen and (max-width: 470px) {
            th, td {
                padding: 0.5em;
            }
        }
    }
`

export default MainContentHolder