import React from 'react';
import { Layout } from '../../components/common';
import Header from '../../components/theme/Header';
import Footer from '../../components/theme/Footer';
import { FormattedMessage } from 'react-intl';
import SEO from '../../components/common/SEO';
import CarouselCustom from '../../components/common/IndexPage/carousel';
import TitleSection from '../../components/common/TitleSection';
import MainContentHolder from './styles';
import PricesComfort from '../../components/common/Prices/pricesComfort';

function importAll(r) {
  return r.keys().map(r);
}

// const imgArray = importAll(require.context('../../../static/Comfort', false, /\.(png|jpe?g|svg)$/));

import img1 from '../../../static/Comfort/01.jpg';
import img2 from '../../../static/Comfort/02.jpg';
import img3 from '../../../static/Comfort/03.jpg';
import img4 from '../../../static/Comfort/04.jpg';
import img5 from '../../../static/Comfort/05.jpg';
import img6 from '../../../static/Comfort/06.jpg';
import img7 from '../../../static/Comfort/07.jpg';
import img8 from '../../../static/Comfort/08.jpg';
import img9 from '../../../static/Comfort/09.jpg';
import img10 from '../../../static/Comfort/10.jpg';
import img11 from '../../../static/Comfort/11.jpg';
import img12 from '../../../static/Comfort/12.jpg';
import img13 from '../../../static/Comfort/13.jpg';
import img14 from '../../../static/Comfort/14.jpg';
import img15 from '../../../static/Comfort/15.jpg';
import img16 from '../../../static/Comfort/16.jpg';
import img17 from '../../../static/Comfort/17.jpg';

let imgArray = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17
];

const apartmentComfort = () => (
  <Layout>
    <React.Fragment>
      <Header />
      <SEO title="ApartmentComfort" />
      <MainContentHolder className="main-content-holder">
        <TitleSection section="comfortHeader" />
        <div className="main-content-holder">
          <CarouselCustom imgArray={imgArray} />
        </div>
        <p className="apartment-description">
          <FormattedMessage id="comfortDescription" />
        </p>
        <ul className="apartmentItemList">
          <li>
            <FormattedMessage id="airConditionerDining" />
          </li>
          <li>
            <FormattedMessage id="washingMachine" />
          </li>
          <li>
            <FormattedMessage id="microwave" />
          </li>
          <li>
            <FormattedMessage id="fridge" />
          </li>
          <li>
            <FormattedMessage id="stove" />
          </li>
          <li>
            <FormattedMessage id="kettle" />
          </li>
          <li>
            <FormattedMessage id="coffemachine" />
          </li>
          <li>
            <FormattedMessage id="hairdryer" />
          </li>
          <li>
            <FormattedMessage id="iron" />
          </li>
          <li>
            <FormattedMessage id="tv" />
          </li>
          <li>
            <FormattedMessage id="wifi" />
          </li>
        </ul>

        <br />
        <div>
          <FormattedMessage id="condition13_1" />
          <FormattedMessage tagName="sup" id="condition13_2" />
          <FormattedMessage id="condition13_3" />
        </div>

        <h3>
          <FormattedMessage id="pricesComfortText" />
        </h3>
        <p>
          <FormattedMessage id="pricesInformation" />
        </p>
        <div className="table-price-holder">
          <PricesComfort />
        </div>
      </MainContentHolder>
      <Footer />
    </React.Fragment>
  </Layout>
);

export default apartmentComfort;
